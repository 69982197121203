.product-card .text-content {
  line-height: 18px;
  display: flex;
  flex-direction: row;
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid #eeeeee;
}

.product-card .text-content:last-child {
  border-bottom: none;
}
.product-card .text-content span {
  line-height: 14px;
}

.product-card .text-content span:nth-child(1) {
  padding-right: 10px;
  flex: 3;
}
.product-card .text-content span:nth-child(2) {
  flex: 2;
}
