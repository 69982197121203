.fab-container {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
}

.quotation-card .text-content {
  line-height: 18px;
}
.quotation-card .text-content span {
  line-height: 14px;
}

.quotation-card .text-content span:nth-child(1) {
  padding-right: 6px;
}

.quotation-filters {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
