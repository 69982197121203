.App {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
}

.mobile-hide {
  display: block;
}

@media screen and (max-width: 768px) {
  .App {
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    overscroll-behavior: none;
  }
  .mobile-hide {
    display: none !important;
  }
}
